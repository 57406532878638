import logo from './logo.svg';
import { useMediaQuery } from 'react-responsive'
import './App.css';
import Home from './Home';
import NetworksPage from './Network';

function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  
  
  return (
    <div className="App" style={{backgroundColor: 'black', position: 'relative'}}>
      <NetworksPage />
      <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0,}}>
      <Home />
      </div>
    </div>
  );
}

export default App;
