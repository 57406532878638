import React from 'react';


const Button = props => {
    return <div style={{backgroundColor: 'rgba(255,255,255,0)',  borderRadius: 0,  cursor: 'pointer'}} className="button" onClick={() => {
        window.open(props.link, '_blank')
    }}>
        {props.text}
    </div>
}

export default Button