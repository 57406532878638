import { useEffect, useRef, useState } from 'react'
import ForceGraph3D from 'react-force-graph-3d';
import { UnrealBloomPass, } from 'three-stdlib'
import { Vector2 } from 'three'

import graphData from './sampleGraph.json'

const CAMERA_DISTANCE = 3200;
const BLOOM_VECTOR = new Vector2( 100, 100 )
const BLOOM_RADIUS = 1;
const BLOOM_STRENGTH = 2;
const BLOOM_THRESHOLD = .1;
const ORBIT_SPEED = Math.PI / 3200;

// this.strength = ( strength !== undefined ) ? strength : 1;
// 		this.radius = radius;
// 		this.threshold = threshold;
// 		this.resolution = ( resolution !== undefined ) ? new Vector2( resolution.x, resolution.y ) : new Vector2( 256, 256 );


const NetworksPage = () => {
    const graphRef = useRef();
    
    const nodes = Object.keys(graphData.nodes).map((id, i) => {return {id, name: id, val: i,color: '#e82755', nodeType: Object.values(graphData.nodes)[i].type} })
    const links = Object.values(graphData.edges).map(edge => {
        return {
            source: edge.start,
            target: edge.end,
            value: 0,//1,
            color: '#e82755',
            particleColor: '#e82755',
            speed: Math.log(Object.values(graphData.edges).filter(link => link.start === edge.start).length/2+1)*0.01,
            
            //curvature: 0,
            //rotation: 0
        }
    })
  
    const gData = {nodes, links}

    

    useEffect(() => {
        graphRef.current && graphRef.current.cameraPosition({ z: CAMERA_DISTANCE });

        let angle = 0;
        const intervalID = setInterval(() => {
            graphRef.current && graphRef.current.cameraPosition({
            x: CAMERA_DISTANCE * Math.sin(angle),
            z: CAMERA_DISTANCE * Math.cos(angle)
          });
          angle += ORBIT_SPEED;
        }, 10);

          const bloomPass = new UnrealBloomPass( BLOOM_VECTOR, BLOOM_STRENGTH, BLOOM_RADIUS, BLOOM_THRESHOLD);
        
          graphRef.current && graphRef.current.postProcessingComposer().addPass(bloomPass);

          return () => {
            clearInterval(intervalID);
            graphRef.current && graphRef.current.postProcessingComposer().removePass(bloomPass);
            graphRef.current && graphRef.current.postProcessingComposer().dispose();
          };
    }, []);

    return <div style={{position: 'relative'}}>
        <ForceGraph3D 
            backgroundColor='black'
        
            ref={graphRef}
            graphData={gData}
            nodeRelSize={.3}
            nodeResolution={3}
            nodeOpacity={.2}
            nodeColor={'color'}
            
            linkResolution={3}
            linkWidth={5}
            linkColor={'color'}
            linkOpacity={.2}

            linkDirectionalParticles={"value"}
            linkDirectionalParticleColor={"particleColor"}
            linkDirectionalParticleSpeed={"speed"}
            linkDirectionalParticleWidth={3}
            linkDirectionalParticleResolution={2}

            //linkDirectionalArrowLength={3.5}
            //linkDirectionalArrowRelPos={1}
            //linkCurvature={0.03}

            // forceEngine={'ngraph'}
            d3AlphaMin={.02}
            d3AlphaDecay={.03}
            d3VelocityDecay={.6}
            
            // ngraphPhysics={{
            //     timeStep: 5,
            //     dimensions: 3,
            //     gravity: -100,
            //     theta: 0.9,
            //     //springLength: 100,
            //     springCoefficient: 0.7,
            //     dragCoefficient: 0.2,
            //   }}

            showNavInfo={false}
        
        />
        <div style={{position: 'absolute', width: '100%', height: '100vh', top: 0, left: 0, pointerEvents: 'none'}} className="flex_centerV text_title_large_white">
        {/* <img src='./logo_circle.png' style={{width: 32, height: 32, filter: 'brightness(10) saturate(0)'}}/> */}
            {/* <div>Click anywhere to jump to a space</div> */}
            {/* <div style={{height: 24}} />
            <div className='text_outline'>
                WELCOME BACK TO THE GRASSROOTS
            </div>
            <div style={{height: 16}} />
            <div className='text_outline'>
                COMING SOON
            </div>
            <div style={{height: 16}} /> */}
        </div>
    </div>
}

export default NetworksPage
